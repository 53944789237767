import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from 'styled-components';
import { Seo } from "../components/seo";
import { Layout } from "../components/Layout/layout"
import InfoMenu from "../components/Info/InfoMenu";
import InfoWrapper from "../components/Info/InfoWrapper";
import InfoTextGroup from "../components/Info/InfoTextGroup";
import InfoLayout from "../components/Info/InfoLayout";
import InfoPageTitle from "../components/Info/InfoPageTitle";
import Section from "../components/Section/Section";
import { media } from '../utils/mediaQuery';

const OverFlowHidden = styled.div`
  overflow: hidden;
`
const PrivacySection = styled(Section)`

`

export default function PrivacyPolicyPage({
  data: { policy },
}) {

  const policyData = policy.data.body.reduce((acc,curr) => {
    const primary = curr.primary;
    const item = primary.menu_title;
    const content = primary.content.html;

    acc.push(Object.assign({content: content},
      item === null ? null : {item},
    ))

    return acc;
  },[])

  return (
    <Layout navBorder>
      <Seo
          title='Privacy Policy'
        />
      <InfoWrapper title='Privacy Policy' data={policyData}/>
    </Layout>
  )
}

export const query = graphql`
  query {
    policy: prismicTermsOfService(id: {eq: "77c348d3-3cb2-54be-9360-a7e1afd7f74e"}) {
      data {
        body {
          ... on PrismicTermsOfServiceDataBodyContentGroup {
            id
            primary {
              content {
                html
              }
              menu_title
            }
            items {
              question
              answer {
                html
              }
            }
          }
        }
      }
      id
    }
  }
`